<template>
  <div class="bid-project-detail-view">
    <swiper-banner></swiper-banner>
    <div class="safty-wrapper">
      <div class="wrap-paper">
        <div class="head-row">
          <div class="head-title-wrap">
            <div class="head-icon"></div>
            <div class="head-content">
              <div class="head-title">投标项目备案</div>
              <div class="head-title-en">TENDER FOR THE RECORD</div>
            </div>
          </div>
          <el-button class="deal-btn" type="primary">立即办理</el-button>
        </div>

        <div class="sub-title-row" style="margin-top: 64px">
          <div class="sub-title-cn">业务介绍</div>
          <div class="sub-title-en">Business Introduction</div>
        </div>

        <div>
          <p class="paragraph-text">
            业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业，，，，务介绍业务介绍业务介绍业绍业务介绍业务介绍业务介绍业，，，，
            介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍
          </p>
        </div>

        <div class="sub-title-row" style="margin-top: 64px">
          <div class="sub-title-cn">适应企业类型</div>
          <div class="sub-title-en">Adapt to the type of business</div>
        </div>

        <div>
          <div class="company-type-text">
            <div class="company-type-icon"></div>
            <span>集成型公司</span>
          </div>
          <p class="paragraph-text">
            业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业，，，，务介绍业务介绍业务介绍业绍业务介绍业务介绍业务介绍业，，，，
            介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍
          </p>
        </div>

        <div class="sub-title-row" style="margin-top: 64px">
          <div class="sub-title-cn">办理流程</div>
          <div class="sub-title-en">Handling Process</div>
        </div>

        <div>
          <p class="paragraph-text">
            业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业，，，，务介绍业务介绍业务介绍业绍业务介绍业务介绍业务介绍业，，，，
            介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍
          </p>

          <div class="deal-process">
            <div class="progress-row">
              <div class="process1">
                <div class="process-box process1-1">
                  务介绍业务介绍务介绍业务介绍业务介绍业务
                </div>
                <div class="process-box process1-2">
                  务介绍业务介绍务介绍业务介绍业务介绍业务
                </div>
                <div class="process-box process1-3">
                  务介绍业务介绍务介绍业务介绍业务介绍业务
                </div>
              </div>
              <div class="process2">
                <div class="process-box process1-1">
                  务介绍业务介绍务介绍业务介绍业务介绍业务
                </div>
                <div class="process-box process1-2">
                  务介绍业务介绍务介绍业务介绍业务介绍业务
                </div>
                <div class="process-box process1-3">
                  务介绍业务介绍务介绍业务介绍业务介绍业务
                </div>
              </div>
            </div>
            <div class="progress-row">
              <div class="process3">
                <div class="process-box process3-1">
                  务介绍业务介绍务介绍业务介绍业务介绍业务
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="sub-title-row">
          <div class="sub-title-cn">产品信息</div>
          <div class="sub-title-en">Product information</div>
        </div>

        <div class="product-info-row">
          <div class="product-image">
            <img :src="detail.poster" alt="" />
          </div>
          <div class="product-content">
            <div class="product-content-item">
              <div class="product-content-label">产品名称</div>
              <div class="product-content-value">{{ detail.name }}</div>
            </div>
            <div class="product-content-item">
              <div class="product-content-label">价格</div>
              <div class="product-content-value">
                {{ detail.price | currency }}元/{{ detail.unit }}
              </div>
            </div>
            <div class="product-content-item">
              <div class="product-content-label">年销售额</div>
              <div class="product-content-value">
                {{ detail.salesVolume | currency }}元
              </div>
            </div>
            <div class="product-content-item">
              <div class="product-content-label">供货地区</div>
              <div class="product-content-value">{{ detail.salesArea }}</div>
            </div>
            <div class="product-content-item">
              <div class="product-content-label">产品介绍</div>
              <div class="product-content-value">
                <p>
                  {{ detail.salesArea }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="sub-title-row" style="margin-top: 64px">
          <div class="sub-title-cn">公司信息</div>
          <div class="sub-title-en">Company information</div>
        </div>

        <div class="company-baseinfo-row">
          <div class="company-picture">
            <img src="" alt="" />
          </div>
          <div class="company-base-content">
            <div class="company-code">公司代码： ISAX000010011088</div>
            <div class="company-title">XXXXXXXX技术有限公司</div>
            <div class="company-type">集成类公司</div>
          </div>
          <div class="company-detail-link-row">
            <a href="javascript:void(0)">了解详情</a>
          </div>
        </div>

        <div class="company-business-row">
          <p>
            业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业，，，，务介绍业务介绍业务介绍业绍
          </p>
          <p>
            介绍业务介绍业务介绍业务介绍业务介XXXXXXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司XXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司
          </p>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import SwiperBanner from "./components/swiperBanner.vue";
export default {
  components: {
    SwiperBanner,
  },
  data() {
    return {
      detail: {},
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let id = this.$route.params.id;
      this.$axios
        .get("/product/productDetails/" + id, {
          params: {
            id: id,
          },
        })
        .then((res) => {
          this.detail = res;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.bid-project-detail-view {
  background: #f9f9f9;
  .safty-wrapper {
    width: $safety-width;
    margin: 0 auto;
    padding: 125px 0 114px 0;
    .wrap-paper {
      background: #ffffff;
      padding: 56px 169px 64px 150px;
      .sub-title-row {
        display: flex;
        align-items: flex-end;

        padding-bottom: 32px;
        .sub-title-cn {
          font-size: 22px;
          color: #333333;
          font-weight: bold;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 100%;
            margin-top: 10px;
            width: 32px;
            height: 2px;
            background: #3883ff;
          }
        }
        .sub-title-en {
          color: #999999;
          font-size: 18px;
          margin-left: 17px;
        }
      }

      .head-row {
        display: flex;
        justify-content: center;
        position: relative;
        // text-align: center;
        .head-title-wrap {
          display: flex;
          .head-icon {
            width: 50px;
            height: 50px;
            background: #ddd;
          }
          .head-content {
            margin-left: 20px;
            color: #333333;
            .head-title {
              font-size: 28px;
            }
            .head-title-en {
              font-size: 16px;
            }
          }
        }
        .deal-btn {
          position: absolute;
          top: 10px;
          right: -100px;
        }
      }

      .company-type-text {
        font-size: 20px;
        color: #263238;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .company-type-icon {
          width: 30px;
          height: 30px;
          background: #ddd;
          margin-right: 10px;
        }
      }

      .paragraph-text {
        font-size: 18px;
        color: #666666;
      }

      .deal-process {
        .process-box {
          width: 255px;
          height: 86px;
          background: #004375;
          color: #ffffff;
          font-size: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 18px 23px;
          box-sizing: border-box;
          margin-top: 48px;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            border-left: 2px dashed #fa6400;
            height: 48px;
          }
          &:first-of-type:before {
            display: none;
          }
        }

        .progress-row {
          display: flex;
          justify-content: space-between;
          padding: 0 50px;
          box-sizing: border-box;
        }
        .process1 {
          position: relative;
          &:before {
            content: "";
            position: absolute;
            bottom: 43px;
            left: 100%;
            width: 300px;
            border-bottom: 2px dashed #fa6400;
          }
        }
        .process2 {
        }
        .process3 {
          width: 100%;
          display: flex;
          justify-content: center;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            bottom: 86px;
            left: 50%;
            height: 86px;
            border-left: 2px dashed #fa6400;
          }
        }
      }
    }
  }
}
</style>